import config from "config";

export function getDefaultBasemapKey() {
  return basemaps.layers[basemaps.default].id;
}

export const getDefaultBasemap = () => {
  return basemaps.layers[basemaps.default];
};

export const basemaps = {
  layers: {
    Light: {
      id: "a2ff0cc0-7e08-4e83-b508-51db6a123465",
      url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      properties: {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">' +
          'OpenStreetMap</a> &copy;<a href="http://cartodb.com/attributions">CartoDB</a>',
        maxZoom: 30,
        preview: "https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/17/38168/49642.png",
      },
      default: true,
    },
    LightNoLabels: {
      id: "light_no_labels",
      url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}.png",
      properties: {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">' +
          'OpenStreetMap</a> &copy;<a href="http://cartodb.com/attributions">CartoDB</a>',
        maxZoom: 30,
        preview:
          "https://cartodb-basemaps-a.global.ssl.fastly.net/light_nolabels/17/38168/49642.png",
      },
      default: true,
    },
    Aerial: {
      id: "024a8c36-2892-4039-ba6f-02a662c8cefa",
      url: "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/{format}?style={style}&size={size}&apiKey={apiKey}",
      properties: {
        attribution: 'Map &copy; 2025 HERE <a href="http://developer.here.com">HERE</a>',
        subdomains: "1234",
        mapID: "newest",
        apiKey: config.hereApiKey,
        baseType: "aerial",
        maxZoom: 30,
        maxNativeZoom: 20,
        language: "eng",
        format: "png8",
        size: "256",
        style: "explore.satellite.day",
      },
    },
  },
  default: "Light",
};
